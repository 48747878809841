import React from "react";
import { Toggle, ToggleLink } from "@commonsku/styles";

type SwitchProps = {
  values: string | string[];
  options: (string | { label: string; value: string })[];
  onChange: (v: string) => void;
  size?: "small" | "medium" | "large";
  stretch?: boolean;
  style?: React.CSSProperties;
};
const Switch = ({
  values,
  onChange,
  options = [],
  size = "medium",
  stretch = false,
}: SwitchProps) => (
  <Toggle size={size} stretch={stretch}>
    {options.map((option) => {
      const value = typeof option === "string" ? option : option.value;
      const label = typeof option === "string" ? option : option.label;

      const isSelected = Array.isArray(values)
        ? values.includes(value)
        : value === values;

      return (
        <ToggleLink
          key={"toggle-op-" + value}
          id={"toggle-op-" + value}
          selected={isSelected}
          style={{ fontSize: "17px", lineHeight: "28px" }}
          onClick={(e) => {
            onChange(value);
          }}
        >
          {label}
        </ToggleLink>
      );
    })}
  </Toggle>
);

export default Switch;
